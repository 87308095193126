<template>
	<div>
		<CRow><CButton size="sm" class="btn btn-link back-btn" 
					@click="backToTable()"><i class="fa fa-arrow-left"></i>Back</CButton>
		</CRow>
		<CCard class="p-2"> 
			<CCardHeader style="padding: 0.50rem 1.25rem">
				<CRow>
					<CCol lg="6">
						<h5>Trucker Settlement  #{{dataParams.invoice_no}}</h5>
					</CCol>
			     	<CCol lg="6">
			      		<div style="text-align: right">
			      			
	      					<CButton v-if="config.getPermission('trucker_settlement').download || true"  color="info" variant="outline" size="sm" @click="download"><i class="fa fa-download"></i> Download</CButton> &nbsp; 
			      			
			      		</div>
					</CCol>
				</CRow>
			</CCardHeader>
			<CCardBody>
					<CRow class="p-1">
						<CCol lg="12">
							<strong style="font-size:13px" class="text-dark">Trucker Settlement Information</strong>
						</CCol>	
					</CRow>
					<CRow class="ml-3 mr-3 mt-0"> 
						<CCol lg="4">
						<div class="form-group">
                         	 <small class="">Vendor</small>
                         	 <strong class="form-text mt-0">
								 {{dataParams.vendor ? dataParams.vendor.vendor_name : "N/A"}}
							  </strong>
                        </div>
						</CCol>
						<!-- <CCol lg="3">
							Vendor: <strong>{{dataParams.vendor ? dataParams.vendor.vendor_name : "N/A"}}</strong>
						</CCol> -->
						<CCol lg="4">
						<div class="form-group">
                         	 <small class="">Customer</small>
                         	 <strong class="form-text mt-0">
								{{dataParams.customer ? dataParams.customer.customer_name : "N/A"}}
							  </strong>
                        </div>
						</CCol>
						<!-- <CCol lg="3">
							Customer: <strong>{{dataParams.customer ? dataParams.customer.customer_name : "N/A"}}</strong>
						</CCol> -->
						<CCol lg="4">
						<div class="form-group">
                         	 <small class="">Status</small>
                         	 <strong class="form-text mt-0 text-uppercase">
						{{dataParams.status ? dataParams.status : "N/A"}}
							  </strong>
                        </div>
						</CCol>
						
						
					</CRow>
					<CRow class="ml-3 mr-3 mt-0"> 
						<CCol lg="4">
						<div class="form-group">
                         	 <small class="">Ref No.</small>
                         	 <strong class="form-text mt-0">
							{{dataParams.ref_no ? dataParams.ref_no : "N/A"}}
							  </strong>
                        </div>
						</CCol>
						
						<CCol lg="4">
						<div class="form-group">
                         	 <small class="">Tax Type</small>
                         	 <strong class="form-text mt-0">
								 {{dataParams.tax_type ? dataParams.tax_type.toUpperCase() : "N/A"}}
							  </strong>
                        </div>
						</CCol>
					
					</CRow> 
			 
					<hr/>

					<CRow class="p-1">
						<CCol lg="12">
							<strong style="font-size:13px" class="text-dark">Covered Trips</strong>
						</CCol>	
					</CRow>
					<CRow class="ml-3 mr-3 mt-0"> 
						<CCol lg="3">
						<div class="form-group">
                         	 <small class="">Delivery Date From</small>
                         	 <strong class="form-text mt-0">
						{{dataParams.delivery_date_from ? moment.unix(dataParams.delivery_date_from).format('MMMM DD, YYYY') : "N/A"}}
							  </strong>
                        </div>
						</CCol>
						
						<CCol lg="3">
						<div class="form-group">
                         	 <small class="">Delivery Date To</small>
                         	 <strong class="form-text mt-0">
						{{dataParams.delivery_date_to ? moment.unix(dataParams.delivery_date_to).format('MMMM DD, YYYY') : "N/A"}}
							  </strong>
                        </div>
						</CCol>
						
						<CCol lg="3">
						<div class="form-group">
                         	 <small class="">Origin</small>
                         	 <strong class="form-text mt-0">
						{{dataParams.origin ? dataParams.origin.setting_name : "N/A"}}
							  </strong>
                        </div>
						</CCol>
						<CCol lg="3">
							<div class="form-group">
								<small class="">Depot</small>
								<strong class="form-text mt-0">
							{{dataParams.depot ? dataParams.depot.setting_name : "N/A"}}
								</strong>
							</div>
						</CCol>
						<!-- <CCol lg="4">
							Origin: <strong>{{dataParams.origin ? dataParams.origin.setting_name : "N/A"}}</strong>
						</CCol>   -->
					</CRow>  
			
			<hr>
					<CRow> 
						<CCol lg="12">
						
							<CDataTable
								:tableFilter='{ placeholder: "Search Booking #" }'
								:items="dataParams.booking"
								:fields="fields"
								hover
								striped
								small
								fixed
								pagination
								:itemsPerPage="20"
								
						    >  
						    	
						    </CDataTable> 
						    <!-- <CPagination
					          	:active-page.sync="currentPage"
					          	:pages="Math.ceil(dataParams.booking.length / this.perPage)"
					          	:activePage="currentPage"
					          	@update:activePage="updatePage"
					        />  -->
						</CCol>  
					</CRow> 
					<CRow>
						<CCol lg="9" class="text-right"></CCol>
						<CCol lg="3" class="text-right">
							<ul class="list-group list-group-flush">
								<!-- <li class="list-group-item d-flex justify-content-between align-items-center">
									Sub Total
									<strong>{{dataParams.sub_total | number_format(null, 2) }}</strong>
								</li>
								<li class="list-group-item d-flex justify-content-between align-items-center">
									Tax (12%)
									<strong>{{dataParams.tax_computation | number_format(null, 2)}}</strong>
								</li> -->
								<li class="list-group-item d-flex justify-content-between align-items-center" style="font-weight:700; font-size:18px">
									Total
									<span>{{dataParams.total_amount | number_format('currency')}}</span>
								</li>
								
							</ul>
							
						</CCol>
					</CRow>
		
			</CCardBody> 
		</CCard>
		<!-- <HistoryModal :details="dataParams" @showModal="showHistoryModal = $event" :showModal="showHistoryModal" /> -->
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
import '../../style.css';
import moment from 'moment';
// import HistoryModal from './history_modal/index';

export default {
	mounted(){  

		this.getData();
		this.getVendor();
		this.getVehicleType();
		this.getMakerModel();
		this.getDepot();
		this.getOrigin(); 

	},
	data(){
		return{
			bookingList: [],
			currentPage: 1,
			perPage: 20,
			formModal: false,
			showViewModal: false,
			// showHistoryModal: false,
			dataList: [],
			dataListInactive: [],
			depotName: "",
			dataParams: {
				customer_id: "",
				origin_id: "",
				commodity_id: "",
				ref_no: "",
				status: "",
				tax_type: "",
				delivery_date_from: "",
				delivery_date_to: "",
				total_amount: 0.00,
				bookings: []
			},
			editMode: false, 
			vendorList: {
				data: []
			},
			vehicleTypeList: {
				data: []
			},
			makerModelList: {
				data: []
			},
			depotList: {
				data: []
			},
			originList: {
				data: []
			},
			isLoading: false,
			uploadPercentage: 0,
			config,
			moment,
			fields:  [
				{
					key: 'booking_no', 
					label: 'Booking #'
				},
				{
					key: 'customer_id_label', 
					label: 'Customer'
				}, 
				{
					key: 'vendor_id_label', 
					label: 'Trucker'
				}, 
				{
					key: 'delivery_date_label', 
					label: 'Delivery Date'
				},   
				{
					key: 'plate_no', 
					label: 'Plate #'
				}, 
				{
					key: 'origin_id_label', 
					label: 'Origin'
				},  
				{
					key: 'depot_name',
					label : 'Depot'
				},
				{
					key: 'subcon_rate', 
					label: 'Subcon Rate'
				},
				{
					key: 'toll_fee', 
					label: 'Toll Fee'
				},
				{
					key: 'other_expenses', 
					label: 'Other Expenses'
				},
				{
					key: 'total_amount', 
					label: 'Total Amount'
				}
			],
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect},
	methods: { 

		updatePage(data){
			this.currentPage = data; 
			this.bookingList = this.dataParams.booking.slice(0);
    		this.bookingList.splice(0, (this.currentPage - 1) * this.perPage); 
    		this.bookingList.splice(this.perPage, this.bookingList.length); 
		}, 

		getData(){
			this.$showLoading(true)
			axios.get(config.api_path+"/trucker_settlement/"+this.$route.params.id)
			.then(response=>{  
				this.$showLoading(false)
				let over_total_amount = 0;
				let sub_total = 0;
				let tax_computation = 0;
				this.dataParams = response.data.data;  
				this.dataParams.booking = this.dataParams.booking.map((value, index)=>{
	    			if(value.booking_type_id){
	    				value.booking_type_id_label = value.booking_type.setting_name
	    			}
	    			else{
	    				value.booking_type_id_label = "N/A"
	    			}

	    			if(value.customer_id){
	    				value.customer_id_label = value.customer.customer_name
	    			}
	    			else{
	    				value.customer_id_label = "N/A"
	    			}

	    			if(value.origin_id){
	    				value.origin_id_label = value.origin.setting_name
	    			}
	    			else{
	    				value.origin_id_label = "N/A"
	    			}
					
	    			if(value.depot_id){
	    				value.depot_name = value.depot.setting_name
	    			}
	    			else{
	    				value.depot_name = "N/A"
	    			}

	    			if(value.destination_id){
	    				value.destination_id_label = value.destination.setting_name
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

	    			if(value.commodity_id){
	    				value.commodity_id_label = value.commodity.setting_name
	    			}
	    			else{
	    				value.commodity_id_label = "N/A"
	    			}

	    			if(value.destination_id){
	    				value.destination_id_label = value.destination.setting_name
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

	    			if(value.vehicle_type_id){
	    				value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			}
	    			else{
	    				value.vehicle_type_id_label = "N/A"
	    			}
					if(value.asset_type == 'vehicle'){
	    				value.asset_no = value.vehicle ? value.vehicle.plate_no : "N/A"
	    			}
	    			if(value.asset_type == 'equipment'){
	    				value.asset_no = value.equipment ? value.equipment.equipment_prep_no : "N/A"
	    			}
	    			if(value.asset_type == 'furnishing'){
	    				value.asset_no = value.furnishing ? value.furnishing.asset_no : "N/A"
	    			}
	    			value.asset_type = value.asset_type.charAt(0).toUpperCase() + value.asset_type.slice(1)
	    			if(value.vehicle_id){
	    				value.plate_no = value.vehicle.plate_no
	    			}
	    			else{
	    				value.plate_no = "N/A"
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date = value.delivery_date
	    			}
	    			else{
	    				value.delivery_date = "N/A"
	    			}
	    			if(value.client_ref_no == "" || value.client_ref_no == null){
	    				value.client_ref_no_label = "N/A";
	    			}

	    			if(value.vendor_id){
	    				value.vendor_id_label = value.vendor.vendor_name
	    			}
	    			else{
	    				value.vendor_id_label = "N/A"
	    			}

	    			if(value.vehicle_type_id){
	    				value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			}
	    			if(value.maker_model_id){
	    				value.maker_model_id_label = value.maker_model.setting_name
	    			}

	    			if(value.with_gps){
	    				value.with_gps_label = "Yes";
	    			}
	    			else{
	    				value.with_gps_label = "No";
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
	    			}
	    			else{
	    				value.delivery_date_label = "N/A"
	    			}

	    			if(value.total_amount){
	    				value.total_amount = value.total_amount;
	    			}
	    			else{
	    				value.total_amount = "0.00"
	    			}

	    			if(value.client_rate){
		    			value.client_rate= value.client_rate
		    		}
	    			
					else{
	    				value.client_rate = "0.00" 
	    			}

					value.toll_fee = (value.billable) ? value.billable.toll_fee : '0.00';
					value.other_expenses = (value.billable) ? value.billable.other_expenses : '0.00';

					
					let total_amount = 0;

					if(value.billable) {
						total_amount = parseFloat(value.billable.toll_fee) + parseFloat(value.billable.other_expenses);
					}
					value.total_amount =  total_amount + parseFloat(value.subcon_rate);
					value.total_amount = (this.dataParams.tax_type && this.dataParams.tax_type == 'vat') ? value.total_amount + (value.total_amount * 0.12) : value.total_amount;
					value.total_amount = value.total_amount.toFixed(2)
					over_total_amount += parseFloat(value.total_amount);

					sub_total = total_amount + parseFloat(value.subcon_rate);
					tax_computation = (this.dataParams.tax_type && this.dataParams.tax_type == 'vat') ? sub_total * 0.12 : 0.00;
					

	    			return value;
	    		}); 

				this.dataParams.total_amount = over_total_amount;
				this.dataParams.sub_total = sub_total;
				this.dataParams.tax_computation = tax_computation;
	    		this.bookingList = this.dataParams.booking.slice(0);
	    		this.bookingList.splice(0, (this.currentPage - 1) * this.perPage); 
	    		this.bookingList.splice(this.perPage, this.bookingList.length); 
			})
			.finally(fin => {
				this.$showLoading(false)
			})


		}, 

	    getVendor(){

	    	axios.get(config.api_path+'/vendor?page=1&limit=100000')
	    	.then(response => {
	    		this.vendorList = response.data;
	    		this.vendorList.data = this.vendorList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.vendor_name;
	    			return value;
	    		})
	    	})

	    },

	    getVehicleType(){

	    	axios.get(config.api_path+'/setting?setting_type=vehicle_type&page=1&limit=100000')
	    	.then(response => {
	    		this.vehicleTypeList = response.data; 
	    	})

	    },

	    getMakerModel(){

	    	axios.get(config.api_path+'/setting?setting_type=maker_model&page=1&limit=100000')
	    	.then(response => {
	    		this.makerModelList = response.data; 
	    	})

	    },

	    getDepot(){

	    	axios.get(config.api_path+'/setting?setting_type=depot&page=1&limit=100000')
	    	.then(response => {
	    		this.depotList = response.data; 
	    	})

	    },

	    getOrigin(){

	    	axios.get(config.api_path+'/setting?setting_type=origin&page=1&limit=100000')
	    	.then(response => {
	    		this.originList = response.data; 
	    	})

	    },

	    updateCompanyOwned(e){
	    	this.dataParams.company_owned = e;
	    },

	    getStatus(status){

	    	if(status == "active"){return "Active";}
	    	if(status == "inactive"){return "Inactive";}
	    	if(status == "incoming"){return "Incoming";}
	    	if(status == "archive"){return "Archive/Sold";} 

	    },

	    transimission(trans){
	    	if(trans == "manual"){return "Manual";}
	    	if(trans == "automatic"){return "Automatic";}
	    },

	    fuelType(fuel){
	    	if(fuel == "gasoline"){return "Gasoline";}
	    	if(fuel == "diesel"){return "Diesel";}
	    },

	    backToTable(){
	    	this.$router.push('/main/trucker_settlement')
	    },

	    download(){ 
	    	this.$showLoading(true)
	    	axios.get(config.api_path+'/reports/trucker_settlement/'+this.$route.params.id)
	    	.then(response=>{
				this.$showLoading(false)
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
	    		setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
	    	})
			.catch(err => {
				this.$showLoading(false)
			})
	    }

 	}
}
</script>
